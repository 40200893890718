import React, { useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Row, Col } from 'react-bootstrap'
import Input from 'Components/Input'
import Wrapper from 'Components/wrapper'
import { formatDate, rules, toaster } from 'helpers'
import { userContext } from 'context/user'
import PageTitle from 'Components/Page-Title'
import { useMutation } from 'react-query'
import { editProfile } from 'Query/Profile/profile.mutation'
import moment from 'moment'

// import Button from 'Components/Button'
// import InterviewEditor from 'Components/Editor'

function Profile() {
  const { state } = useContext(userContext)
  const { control, reset, handleSubmit,getValues } = useForm()

  const updateMutation = useMutation((data) => editProfile(data), {
    onSuccess: (res) => {
      toaster(res.data.message)
    },
  })

  
  useEffect(()=>{
    if (state?.user){
      const oUserInfo = state?.user
      reset({
        sName: oUserInfo?.sUserName ||  "",
        sEmail: oUserInfo?.sEmail || "",
        isAdmin: oUserInfo?.isAdmin || false,
        oSubscription:{
          sName: oUserInfo?.oSubscriptionInfo?.sName || "",
          dStartDate: formatDate(oUserInfo?.oSubscriptionInfo?.dStartDate, '-', true)  || "",
          dEndDate: formatDate(oUserInfo?.oSubscriptionInfo?.dEndDate, '-', true)  || "",
          sDescription: oUserInfo?.oSubscriptionInfo?.sDescription || "",
          sStatus: oUserInfo?.oSubscriptionInfo?.eStatus || ""
        }
      })
    }
  }, [state])

  const onSubmit = (data) => {
    const updateData = {
      sUserName: data?.sName,
      sEmail: data?.sEmail,      
    }
    updateMutation.mutate({ ...updateData })
  }

  return (
    <div className='subscription-section'>
      <Row className="mt-3">
        <Col md={6} lg={5} xl={4}>
          <Wrapper >
            <div className="pageTitle-head">
              <PageTitle
                title=""
                BtnText={"Update"}
                handleButtonEvent={handleSubmit(onSubmit)}
              />
            </div>
            <Row className="mt-3">
              <Col xs={12}>
                <Controller
                  name="sName"
                  control={control}
                  rules={rules?.global('Name')}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      labelText='Name'
                      isImportant='true'
                      placeholder="Enter Name"
                      id="sName"
                      errorMessage={error?.message}
                    />
                  )}
                />
              </Col>
              <Col xs={12}>
                <Controller
                  name="sEmail"
                  control={control}
                  rules={rules?.email('Email')}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      labelText='Email'
                      isImportant='true'
                      placeholder="eg.: example@gmail.com"
                      id="sEmail"
                      errorMessage={error?.message}
                    />
                  )}
                />
              </Col>
            </Row>
          </Wrapper>
          </Col>
          {
          !!getValues('oSubscription')?.sName && <Col md={6} lg={6} xl={5}>
              <Wrapper className={"subscription-details"}>
                <Row className='align-items-center'>
                  <Col sm={6} xs={12}>
                    <h4>{getValues('oSubscription')?.sName}</h4>
                  </Col>
                  <Col sm={6} xs={12}>
                    <h5 style={{ fontSize: '13px', textAlign: 'end' }}>Valid till {moment(getValues('oSubscription')?.dEndDate).format('DD-MM-YYYY')}</h5>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} className='mt-2 mt-lg-3'>
                    <div dangerouslySetInnerHTML={{ __html: getValues('oSubscription')?.sDescription }}></div>
                  </Col>
                </Row>
              </Wrapper>
            </Col>
          }
          
      </Row>
    </div>
  )
}

export default Profile
