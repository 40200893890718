import { Crypt } from 'hybrid-crypto-js'
import AltAvatar from 'Assets/Icons/altImage.svg'
import { queryClient } from 'App'
import React from 'react'
import moment from 'moment'

export const floatingNumber = /[a-z]?[A-Z]?[-]?[ $&+,:;`=_?@#|/'<>-^*()%!]*/g

// export const floatingNumber = /^(?![0-9]+(\.?[0-9]?[0-9]?)?)/g

export const onlyInt = /\D+/g
export const onlyDigits = /^[0-9]*$/

// Function for Verify Length
export function verifyLength(value, length) {
  return value?.length >= length
}

// Remove HTML tags and make single string
export function removeHTMLTags(data) {
  return data.replace(/<[^>]+>/g, ' ')
}

export function checkConfirmPassword(password, newPassword) {
  return newPassword === password && newPassword.trim().length > 0 && password.trim().length > 0
}

export function formatDate(date, separator = '-', reverseDate = false) {
  const d = new Date(date).toString().split(' ')
  const formatted = reverseDate ? [d[2], months[d[1]], d[3]].reverse() : [d[2], months[d[1]], d[3]]
  return date && formatted.join(separator)
}

export function convertTo12HourFormat(time) {
  const [hours, minutes] = time?.split(':') || ''
  let formattedTime

  if (parseInt(hours, 10) === 12) {
    formattedTime = `${hours}:${minutes} PM`
  } else if (parseInt(hours, 10) === 0) {
    formattedTime = `12:${minutes} AM`
  } else if (parseInt(hours, 10) > 12) {
    formattedTime = `${parseInt(hours, 10) - 12}:${minutes} PM`
  } else {
    formattedTime = `${hours}:${minutes} AM`
  }

  return formattedTime
}

export function convertTo24HourFormat(time) {
  const [rawTime, meridiem] = time.split(' ')
  const [hours, minutes] = rawTime.split(':')
  let formattedTime

  if (meridiem === 'PM' && parseInt(hours, 10) !== 12) {
    formattedTime = `${parseInt(hours, 10) + 12}:${minutes}`
  } else if (meridiem === 'AM' && parseInt(hours, 10) === 12) {
    formattedTime = `00:${minutes}`
  } else {
    formattedTime = `${hours}:${minutes}`
  }

  return formattedTime
}

export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}
export const monthsForChart = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export const mockChartData = [
  { month: 1, count: 100 },
  { month: 2, count: 150 },
  { month: 3, count: 200 },
  { month: 4, count: 250 },
  { month: 5, count: 300 },
  { month: 6, count: 350 },
  { month: 7, count: 400 },
  { month: 8, count: 450 },
  { month: 9, count: 500 },
  { month: 10, count: 550 },
  { month: 11, count: 600 },
  { month: 12, count: 650 },
]

// Function to convert month number to month name
export function getMonthName(monthNumber) {
  return new Date('1999-' + monthNumber + '-15').toLocaleString('en-us', { month: 'short' })
}

export function checkLength(data = '') {
  if (data?.trim()?.length !== 0) return false
  return true
}

export function removeSpaces(data) {
  return data?.trim()
}

export function removeAllSpaces(data) {
  return new Promise((resolve) => {
    Object.keys(data).forEach((k) => {
      data[k] = removeSpaces(data[k])
    })
    resolve(data)
  })
}

const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAnOttI89r4Q9eZr2pleIY
pOtFD6Jz2INzRiNJmmAh0NQj2HE4/jhAqojAO6mU5h4UT4AxvF1EDz6QJ+OD6KW1
UlAjXJ7wm+M7IUUmpDmev1rTSEW5TKPgp85epnJnJJ/wYJ2hmj/cIpJHcoSl+xjM
T1x6fzsTirwnC6hRjTthBq1WZOsulAw+s0lkOmhO+WfZ1Dc6S3ljJJ2qYEN7H8gS
KXcMYzCSXdSfC0rU6ZynwwKvVnwhRvbT4FuU9T8U9cXfQ7I/PJJ32VDeCNTBD2rF
wLOpuEnOkjcc+QufliCV0sVMScIBXqEuyEHn4bQRFXl60K6zxfswMZYlnwaOSsIL
ycrpIvb72ALNUmeAPrgRUxTdy/uMzcahOw85UHewjN37yFPrVY9p3FHqGaAemtuY
8LDPU9zjKNtxwSyBnIpsK0Iil8s8BR8Y0V3zg4OQ9AneosNXSxGJjZcL9eoVNu+Q
Y4MdX4AfvPYeN//hXJ98MzYZ/Mg95mOeikryE4cL7D81erOh5rJeFLorAzQ3cRx8
K9M1qKPMC1Te51e8hfLX+H2fp0HeGKBAQKAnwyMTN6eRkXZ+cwtYN2xOkhBooU5T
Ydv+uMSN1/CjaiHsLsA1O8vvPyZqkTN66bVg8uzz25QB6nkP7Cf7ntoDYOsP1gPx
1af+T7DI3A/qiZq+GTlrg8UCAwEAAQ==
-----END PUBLIC KEY-----`

export function encryption(sPassword) {
  const crypt = new Crypt()
  const encrypted = crypt.encrypt(publicKey, sPassword)
  return encrypted.toString()
}

export const addQueryParams = (value) => {
  const data = { ...value }
  Object.keys(data).forEach((e) => (data[e] === '' || typeof data[e] === 'object' || !data[e]?.toString().length) && delete data[e])
  return new URLSearchParams(data)?.toString()
}

export function checkObjectId(id) {
  return /^[a-f\d]{24}$/i.test(id)
}

export function removeToken(isLocalStorage) {
  console.warn('removing token')
  if (isLocalStorage) {
    localStorage.clear('')
  } else {
    sessionStorage.clear('')
  }
}

export function addToken(token, remember) {
  if (remember) {
    localStorage.setItem('token', token)
  } else {
    sessionStorage.setItem('token', token)
  }
}

export const appendParams = (value) => {
  const data = { ...value }
  data.search = encodeURIComponent(data?.search || '')
  Object.keys(data).forEach((e) => (data[e] === '' || typeof data[e] === 'object' || !data[e]?.toString().length) && delete data[e])

  window.history.replaceState({}, null, `${location.pathname}?${new URLSearchParams(data).toString()}`)
}

export const parseParams = (params = '') => {
  const urlParams = new URLSearchParams(decodeURIComponent(params))
  const rawParams = decodeURIComponent(params).replace('?', '').split('&')

  const extractedParams = {}
  if (rawParams[0]) {
    rawParams.forEach((item) => {
      item = item.split('=')
      extractedParams[item[0]] = urlParams.get(item[0]) ? urlParams.get(item[0]) : ''
    })
    return extractedParams
  } else {
    return extractedParams
  }
}

export function handleAlterImage(e, src, isStyled) {
  e.target.onerror = null
  e.target.style = isStyled ? 'width:55%;height:55%;' : ''
  e.target.src = src || AltAvatar
}

export function getSortedColumns(TableColumns, urlData) {
  return TableColumns?.map((column) =>
    column.connectionName === urlData?.sort
      ? { ...column, sort: urlData?.order === 'asc' ? 1 : urlData?.order === 'desc' ? -1 : 0 }
      : column
  )
}

export function toaster(message, type = 'success') {
  queryClient.defaultOptions.message({ message, type })
}

export function imageAppendUrl(url) {
  return 'https://jr-web-developer.s3.ap-south-1.amazonaws.com/' + url
}

export function navigationTo(link) {
  emitEvent('navigateTo', link)
}

export function cell(data, optionalText = '-') {
  return data ?? optionalText
}

export function handleErrors(errors, errorSetter) {
  Array.isArray(errors) && errors?.forEach((error) => errorSetter(error.param, { type: 'custom', message: error.msg }))
}

export const bottomReached = ({ target }) => {
  return target.offsetHeight + target.scrollTop >= target.scrollHeight
}

export const transactionTags = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Parsial', value: 'Parsial' },
  { label: 'Full', value: 'full' },
]

function precisionRound(number, precision) {
  var factor = Math.pow(10, precision)
  var n = precision < 0 ? number : 0.01 / factor + number
  return Math.round(n * factor) / factor
}

export function convertMinutesToHour(m, precision = 2) {
  return precisionRound(m / 60, precision)
}

export function emitEvent(type, detail = {}, elem = document) {
  if (!type) return

  let event = new CustomEvent(type, {
    bubbles: true,
    cancelable: true,
    detail: detail,
  })

  return elem.dispatchEvent(event)
}

export function calculateMinutesBetweenTwoDates(startDate, endDate) {
  const isDateFormat = (d) => typeof d === 'object'
  startDate = isDateFormat(startDate) ? isDateFormat : new Date(startDate)
  endDate = isDateFormat(endDate) ? isDateFormat : new Date(endDate)
  var diff = endDate.getTime() - startDate.getTime()

  // output diff in in minutes
  return Math.floor(diff / 1000 / 60)
}

export function detectBrowser() {
  var N = navigator.appName,
    ua = navigator.userAgent,
    M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d.]+)/i)
  // if (M && (tem = ua.match(/version\/([.\d]+)/i)) != null) M[2] = tem[1]
  M = M ? [M[1]] : [N, navigator.appVersion, '-?']
  return M.join('')
}

export function mapFilter(array, mapCallback, filterCallback) {
  const mappedData = Array.isArray(array) ? [] : {}
  for (const INDEX in array) {
    const singleItem = mapCallback ? mapCallback(array[INDEX]) : array[INDEX]
    const condition = filterCallback ? filterCallback(array[INDEX]) : true
    condition && (Array.isArray(array) ? mappedData.push(singleItem) : (mappedData[INDEX] = array[INDEX]))
  }
  return mappedData
}

export function createOption(input, keys = ['sName', '_id']) {
  return {
    [keys[0]]: input,
    [keys[1]]: input.toLowerCase().replace(/\W/g, ''),
  }
}

export const permissions = ['VIEW_DASHBOARD', 'VIEW_CUSTOMERS', 'VIEW_TRAINERS']

export const permissionsName = {
  VIEW_DASHBOARD: 'noRole',
  VIEW_CUSTOMERS: 'noRole',
  VIEW_TRAINERS: 'noRole',
  VIEW_SUBSCRIPTION: 'noRole',
  VIEW_TRANSACTIONS: 'noRole',
}

export function isGranted(allowed) {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token') || queryClient.getQueryData('')
  const data = token ? parseJwt(token) : null

  if (data?.aTotalPermissions?.length) {
    if (Array.isArray(allowed)) {
      const granted = !!allowed.filter((per) => data.aTotalPermissions.includes(per) || per === 'noRole').length
      return granted
    } else {
      const granted = data.aTotalPermissions.includes(allowed) || allowed === 'noRole'
      return granted
    }
  } else if (allowed === 'noRole') {
    return true
  } else {
    return false
  }
}

export function parseJwt(token) {
  try {
    return JSON.parse(window.atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const MaritalStatus = [
  { label: 'Married', value: 'M' },
  { label: 'Un-Married', value: 'U' },
]

export const FoodType = [
  { label: 'Vegetarian', value: 'V' },
  { label: 'Non-Vegetarian', value: 'N' },
  { label: 'Other', value: 'O' },
]

export const SmokeType = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const eStatus = [
  { value: 'Y', label: 'Active' },
  { value: 'F', label: 'Freezed' },
  { value: 'E', label: 'Expired' },
  { value: 'D', label: 'Deleted' },
  { value: 'U', label: 'UpComing' },
]

export const ePaymentStatus = [
  { value: 'C', label: 'Fully Paid' },
  { value: 'P', label: 'Partially Completed' },
]

export const Gender = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Other', value: 'O' },
]

export const UserType = [
  { label: 'Staff', value: 'S' },
  { label: 'Trainer', value: 'T' },
]

export const UserEvents = [
  { label: 'Birthday', value: 'B' },
  { label: 'Anniversary', value: 'A' },
]

export const rules = {
  global: function (name) {
    return { required: `${name} is required` }
  },
  select: function (name) {
    return { required: `Please select the ${name}` }
  },
  contact: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: /^[0-9]{10}$/,
        message: 'Invalid Contact Number. It should be a 10-digit number.',
      },
    }
  },
  email: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
        message: 'Invalid email format',
      },
    }
  },
  integer: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: onlyDigits,
        message: 'charges must be in int',
      },
    }
  },
  percentage: function (name) {
    return {
      ...rules?.global(name),
      max: {
        value: 100,
        message: 'Commission must be from 0 - 100%',
      },
    }
  },
  maxValidation: function (name, maxValue) {
    return {
      ...rules?.global(name),
      max: {
        value: maxValue,
        message: `value must be less than ${maxValue}`,
      },
    }
  },
}

export function calculateAge(birthday) {
  var ageDifMs = Date.now() - birthday?.getTime()
  var ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const joyRideSteps = [
  {
    content: (
      <div>
        <h4>Welcome to Gymusy!</h4>
        <span style={{ fontSize: '15px' }}>
          Welcome to Gymusy&apos;s comprehensive gym management solution. This tour will guide you through the key features of our platform,
          designed to streamline your gym&apos;s operations and enhance your members experiences. Let&apos;s get started!
        </span>
      </div>
    ),
    placement: 'center',
    target: 'body',
  },
  {
    content: (
      <div>
        <h4>Dashboard Reports</h4>
        <span style={{ fontSize: '15px' }}>
          The Dashboard provides a quick and easy-to-understand overview of your gym&apos;s performance. Here, you&apos;ll find key metrics
          such as total members, active subscriptions, total inquiries, and more. The dashboard is customizable, you can request customized
          reports as per you need.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    offset: -220,
    placement: 'auto',
    target: '.wrapper_section',
  },
  {
    content: (
      <div>
        <h4>Employee Management</h4>
        <span style={{ fontSize: '15px' }}>
          Efficiently manage your gym&apos;s staff with our Employee Management module. Add and update employee profiles, set roles and
          track their performance. Our platform also includes features for scheduling and tracking employee hours, making payroll a breeze.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.employee-section',
  },
  {
    content: (
      <div>
        <h4>Customer Management</h4>
        <span style={{ fontSize: '15px' }}>
          Our Customer Management module puts your members at the heart of your business. Easily add and update member profiles & manage
          membership tiers, billing, and renewals from this module.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.customers-section',
  },
  {
    content: (
      <div>
        <h4>Subscription Management</h4>
        <span style={{ fontSize: '15px' }}>
          Streamline your membership offerings with our Subscription Management module. Create and manage membership tiers, set pricing and
          billing cycles, and track member subscriptions.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.subscription-section',
  },
  {
    content: (
      <div>
        <h4>Transaction Management</h4>
        <span style={{ fontSize: '15px' }}>
          Keep your gym&apos;s finances in check with our Transaction Management module. Track all financial transactions, including
          monthly/yearly membership fees, product sales, and expenses. Generate financial reports and export data for accounting purposes.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.transactions-section',
  },
  {
    content: (
      <div>
        <h4>Inquiry Management</h4>
        <span style={{ fontSize: '15px' }}>
          Keep your gym&apos;s finances in check with our Transaction Management module. Track all financial transactions, including
          monthly/yearly membership fees, product sales, and expenses. Generate financial reports and export data for accounting purposes.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.inquiry-section',
  },
  {
    content: (
      <div>
        <h4>Settings</h4>
        <span style={{ fontSize: '15px' }}>
          Our flexible settings ensure that the platform works the way you do. You can Manage multiple gym branches, set up batch schedules
          for classes and training sessions, define employee designations and roles, and customize lifestyle settings for your members.
        </span>
      </div>
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 0,
    placement: 'auto',
    target: '.setting-section',
  },
]



export const upcomingBirthdays = [
  {
    name: 'John Doe',
    date: '2024-08-23',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-27',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-27',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-22',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'John Doe 2',
    date: '2024-08-24',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-24',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'John Doe 2',
    date: '2024-08-24',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
]

export const upcomingAnniversaries = [
  {
    name: 'Rahul Kamal',
    date: '2024-08-23',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rakhi Smith',
    date: '2024-08-26',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'RJ Test',
    date: '2024-08-28',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'John Doe 2',
    date: '2024-08-27',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
]


export const FollowUpDueIn7Days = [
  {
    name: 'John Doe',
    date: '2024-08-24',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-27',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rahi Smith',
    date: '2024-09-27',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rahi Smith',
    date: '2024-08-22',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'John Doe 2',
    date: '2024-08-22',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s',
  },
]

export const PaymentDueInNextSevenDays = [
  {
    name: 'Rk Smith',
    date: '2024-08-22',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rj Smith',
    date: '2024-08-22',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'RkD Smith',
    date: '2024-08-27',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
  {
    name: 'Rjc Smith',
    date: '2024-08-26',
    img: 'https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png',
  },
]

const today = moment().startOf('day')
const tomorrow = moment(today).add(1, 'days')


export const formatDateForTabs = (date) => {
  return moment(date).isSame(tomorrow, 'day') ? 'Tomorrow' : moment(date).format('MMMM DD')
}

export const filterItemsDueInNext7Days = (items, today) => {
  return items?.filter(
    (item) => moment(item.date).isSameOrAfter(today, 'day') && moment(item.date).isBefore(moment(today).add(7, 'days'), 'day')
  )
}
