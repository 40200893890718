import React from 'react'
import PropTypes from 'prop-types'

export default function ToastIcon({ fill = '#27B98D', ...props }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.00036 16C12.4115 16 16 12.4115 16 8.00036C16 3.58923 12.4115 0 8.00036 0C3.58923 0 0 3.5885 0 8.00036C0.000726711 12.4115 3.58923 16 8.00036 16ZM8.00036 1.59004C11.5358 1.59004 14.41 4.46564 14.41 8.00036C14.41 11.5344 11.5358 14.41 8.00036 14.41C4.46564 14.41 1.59004 11.5344 1.59004 8.00036C1.59077 4.46564 4.46637 1.59004 8.00036 1.59004Z"
        fill={fill}
      />
      <path
        d="M8.00034 9.70669C8.43928 9.70669 8.79537 9.35132 8.79537 8.91094V4.6713C8.79537 4.23237 8.43855 3.87628 8.00034 3.87628C7.56214 3.87628 7.20532 4.23237 7.20532 4.6713V8.91094C7.20532 9.35132 7.56214 9.70669 8.00034 9.70669ZM8.00034 12.1237C8.43928 12.1237 8.79537 11.7684 8.79537 11.3287V11.1964C8.79537 10.7575 8.43855 10.4014 8.00034 10.4014C7.56214 10.4014 7.20532 10.7568 7.20532 11.1964V11.3287C7.20532 11.7684 7.56214 12.1237 8.00034 12.1237Z"
        fill={fill}
      />
    </svg>
  )
}

ToastIcon.propTypes = {
  fill: PropTypes.string,
}
